<template>
  <list-layout>
    <template slot="search">
      <b-col cols="4">
        <div class="position-relative">
          <b-form-input v-model="search" placeholder="Search" class="pr-12" @keyup.enter="searchTitle = search" />
          <font-awesome-icon
            :icon="['fas', 'search']"
            fixed-width
            class="input-search text-primary"
            @click="searchTitle = search"
          />
        </div>
      </b-col>
      <b-col cols="2">
        <b-form-select v-model="model" :options="modelList">
          <template #first>
            <b-form-select-option :value="null">
              Model
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-form-select v-model="category" :options="categoryList">
          <template #first>
            <b-form-select-option :value="null">
              Category
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </template>
    <template slot="actions">
      <b-button
        variant="secondary"
        class="btn-action text-primary"
        @click="onAddClick"
      >
        <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
      </b-button>
    </template>
    <template slot="list">
      <b-table
        hover
        borderless
        :items="sortFaqList"
        :fields="fields"
        :current-page="currentPage"
        :per-page="rowPrePage"
      >
        <template #head(title)>
          <div class="cursor-pointer" @click="sortList('title')">
            TITLE
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(published)>
          <div class="cursor-pointer" @click="sortList('published')">
            日期
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(category)>
          <div class="cursor-pointer" @click="sortList('category')">
            CATEGORY
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(model)>
          <div class="cursor-pointer" @click="sortList('model')">
            MODEL
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(version)>
          <div class="cursor-pointer" @click="sortList('version')">
            版本
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(download)>
          下載
        </template>
        <template #head(action)>
          <div />
        </template>
        <template #cell(published)="data">
          {{ $dayjs(data.item.published).format('MMM D, YYYY') }}
        </template>
        <template #cell(model)="data">
          {{ getModelText(data.item.model) }}
        </template>

        <template #cell(category)="data">
          {{ getCategoryText(data.item.category) }}
        </template>
        <template #cell(download)="data">
          <b-link class="text-gray-500" href="#" @click.prevent="onDownloadClick(data.item)">
            <font-awesome-icon :icon="['fas', 'download']" fixed-width />
          </b-link>
        </template>
        <template #cell(action)="data">
          <b-row class="mx-0">
            <b-col class="pr-0">
              <b-button
                variant="danger"
                class="btn-action btn-width inline"
                style="box-shadow:none;width:2rem;height:2rem"
                @click="onDeleteClick(data.item)"
              >
                <font-awesome-icon :icon="['fas', 'trash-alt']" fixed-width />
              </b-button>
            </b-col>
            <b-col class="px-0">
              <b-button
                variant="primary"
                class="btn-action btn-width inline"
                style="box-shadow:none;width:2rem;height:2rem"
                @click="onEditClick(data.item)"
              >
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="rowPrePage"
          align="fill"
          size="sm"
          class="my-0"
          first-number
          last-number
        />
        <row-count :total-rows="totalRows" :row-pre-page="rowPrePage" :current-page="currentPage" />
      </div>
      <confirm-modal ref="confirmModal" @submit="confirmDelete" />
    </template>
  </list-layout>
</template>
<script>
import listLayout from '@/components/layouts/partner/listLayout.vue'
import modelList from '@/common/modelList'
import categoryList from '@/common/categoryList'
import confirmModal from '@/components/faq/confirmDeleteModal'
import { getFaqList, deleteFaq, getFile } from '@/api/faq'
import RowCount from '@/components/common/rowCount'

export default {
  name: 'FaqList',
  components: {
    listLayout,
    confirmModal,
    RowCount
  },
  data () {
    return {
      search: '',
      searchTitle: '',
      model: null,
      category: null,
      field: 'title',
      ascending: true,

      faqList: [],
      currentPage: 1,
      rowPrePage: 10
    }
  },
  computed: {
    modelList () {
      return modelList
    },
    categoryList () {
      return categoryList
    },
    fields () {
      return ['title', 'model', 'category', 'version', 'published', 'download', 'action']
    },
    filterFaqList () {
      return this.faqList.reduce((list, faq) => {
        if ((!this.searchTitle && this.model === null && this.category === null) ||
          (this.searchTitle && faq.title.indexOf(this.searchTitle) >= 0) ||
       (this.model !== null && this.category === null && faq.model === this.model) ||
       (this.category !== null && this.model === null && faq.category === this.category) ||
       (this.model !== null && this.category !== null && faq.model === this.model && faq.category === this.category)) {
          list.push(faq)
        }

        return list
      }, [])
    },
    sortFaqList () {
      return this.filterFaqList.slice().sort((a, b) => {
        const dataA = String(a[this.field]).toLowerCase()
        const dataB = String(b[this.field]).toLowerCase()
        if (dataA > dataB) return this.ascending ? 1 : -1
        if (dataA < dataB) return this.ascending ? -1 : 1
        else return 0
      })
    },
    totalRows () {
      return this.sortFaqList.length
    }
  },
  created () {
    this.getFaqList()
  },
  methods: {
    getModelText (model) { return modelList.map(p => p.options).flat().find(a => a.value === model)?.text },
    getCategoryText (category) {
      return this.categoryList.find(c => c.value === category)?.text
    },
    getFaqList () {
      getFaqList().then(res => {
        this.faqList = res.data.data
      })
    },
    sortList (field) {
      if (this.field === field) {
        this.ascending = !this.ascending
      } else {
        this.field = field
        this.ascending = true
      }
    },
    onDownloadClick (item) {
      getFile(item.file_uri).then((res) => {
        const fileUrl = URL.createObjectURL(res.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileUrl
        fileLink.setAttribute('target', '_blank')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    },
    onAddClick () {
      this.$router.push({ name: 'FaqAction', params: { action: 'create' } })
    },
    onEditClick (item) {
      this.$store.commit('SELETE_FAQ', item)
      this.$router.push({ name: 'FaqAction', params: { action: 'edit' } })
    },
    onDeleteClick (item) {
      this.$refs.confirmModal.show(item)
    },
    confirmDelete (item) {
      deleteFaq(item.id).then(() => {
        this.getFaqList()
      })
    }
  }
}
</script>
