<template>
  <b-modal
    id="del-faq-confirm-modal"
    title="Confirm Delete"
    cancel-title="CANCEL"
    ok-title="CONFIRM"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <p class="mb-4">
        Confirm delete record: <b>{{ item.title }} ({{ item.published }})</b> ?
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data () {
    return {
      item: { title: '', date_published: '' }
    }
  },
  methods: {
    show (item) {
      this.$bvModal.show('del-faq-confirm-modal')
      this.item = item
    },
    hide () {
      this.$bvModal.hide('del-faq-confirm-modal')
    },
    submit () {
      this.$emit('submit', this.item)
    }
  }
}
</script>
